@import "./../../../vars.css";

.cardContainer {
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding: 1em;
    --border: 1px solid var(--color-light);
    animation-name: example;
    animation-duration: 2s;
    border-radius: 1em;
    background-color: var(--color-dark-secondary);
}

    
@keyframes example {
    from {
        box-shadow: 0 0 10px var(--color-light)
    }

    to {
        box-shadow: 0;
    }
}