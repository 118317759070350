.button {
    font-size: 1em;
    border: 0;
    border-radius: 0.5em;
    padding: 0.25em; 
    color: #fff;
    background-color: var(--color-primary);
    box-sizing: border-box;  
}

.button:hover:enabled {
    box-shadow: 0px 0px 5px var(--color-light); 
}

.button:disabled {
    opacity: 0.3;
}
