@import "../../vars.css";

.loginOptionsContainer {
    display: flex;
    justify-content: center;
    padding: 2em;
    border-radius: 1em;
    background-color: var(--color-dark-secondary);
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.loginOptionsList {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.header {
    display: flex;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 3em;
    align-items: center;
}

.logo {
    width: 250px;
    margin: 2em;
}