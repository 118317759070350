@import "./../../../vars.css";


.controlGroup {
    display: flex;
    flex-direction: column;
    margin: 1em 0 1em 0;
    width: 100%;
}

.inputContainer {
    display: flex;
    gap: 0.5em;
    align-items: top;
}

.checkbox {
    margin-top: 0.25em;
    font-size: 1em;
    color: green;
}

.error {
    margin-top: 0.25em;
    font-size: 1em;
    color: red
}