@import "../../../vars.css";

.signInIcon {
    width: 100px;
    border-radius: 1.5em;
}

.signInIcon:hover {
    box-shadow: 0 0 10px var(--color-light);
    cursor: pointer;
}

.sessionInputContainer {
    display: flex;
}
